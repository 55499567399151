import React, { Component, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./scss/style.scss";
import "@mantine/core/styles.css";
import Wrapper from "./Wrapper";
import ResetPassword from "./views/pages/ResetPass/ResetPassword";
import ForgetPass from "./views/pages/ForgetPassword/ForgetPass";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
// const ResetPassword = React.lazy(() => import("./views/pages/resetPassword/ResetPassword"));

class App extends Component {
  
  render() {
   
    return (
      <BrowserRouter>
        <Wrapper auth={true}>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="/setup-password" element={<ResetPassword />} />
              <Route path="/forget-password" element={<ForgetPass />} />

              {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
              <Route path="*" name="Home" element={<PrivateRoute />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
            </Routes>
          </Suspense>
        </Wrapper>
      </BrowserRouter>
    );
  }
}

// Define PrivateRoute component to guard routes
const PrivateRoute = () => {
  const location = useLocation();
  const firstLogin = sessionStorage.getItem("first_login"); 
  const token = sessionStorage.getItem("token"); // Access token
  // const paramToken=new URLSearchParams(location.search).get("token");
// console.log("paramToken",paramToken)
//   if (paramToken) {
//     return <Navigate to={`/setup-password?token=${token}`} replace />;
//   }

  if (token && firstLogin === "true") {
    return <Navigate to="/setup-password" replace />;
  }



  // Access the route name
  const routeName = location.pathname;

  useEffect(()=>{
    if(!routeName?.includes("author-article")){
      localStorage.setItem("AuthorPage",1);
    }
    if(!routeName?.includes("featured-article")){
      localStorage.setItem("FeatureArticlePage",1);
    }
    if(!routeName?.includes("top-story-article")){
      localStorage.setItem("TopStoryPage",1);
    }
    if(!routeName?.includes("narrative-article")){
      localStorage.setItem("NarrativePage",1);
    }
    if(!routeName?.includes("opinion")){
      localStorage.setItem("OpinionPage",1);
    }
    if(!routeName?.includes("images")){
      localStorage.setItem("ImagePage",1);
    }
    if(!routeName?.includes("podcast")){
      localStorage.setItem("PodcastPage",1);
    }
    if(!routeName?.includes("videos")){
      localStorage.setItem("VideoPage",1);
    }
    if(!routeName?.includes("news")){
      localStorage.setItem("NewsListPage",1);
    }

  },[routeName])


  return sessionStorage.getItem("token") ? (
    <DefaultLayout />
  ) : (

    <Navigate to="/login" replace />

  );
};

export default App;
