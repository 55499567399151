import { removeCookie } from "./cookie";
import {
  DialogClose,
  DialogOpen,
  DrawerClose,
  DrawerOpen,
  PagerLoader,
} from "./rxSubjects";
import { IDrawerProps, IDrawersNames } from "./types";

/**
 * @description use this method to pause a task for some time
 * @param time time in milliseconds
 * @returns Promise
 */
const sleep = (time) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });

/**
 * @description use this method to parse query params
 * @param query url query params - search params
 * @returns Object: { key: value }
 */
const parseQuery = (query) => {
  query = query.trim().replace(/^[?#&]/, "");
  const queryParam = {};
  for (const param of query.split("&")) {
    if (param === "") {
      continue;
    }
    let [key, value] = param.split("=");
    queryParam[key] = value;
  }
  return queryParam;
};

/**
 * @description use this method to parse URL pathname (routes into an array)
 * @param param url pathname
 * @returns Array of strings, e.g., ["signup", "merchant"]
 */
const parseParam = (param) => {
  param = param.trim().replace(/^[/#&]/, "");
  return param.split("/");
};

const clearAll = () => {
  removeCookie("refreshToken");
  removeCookie("accessExpiry");
  removeCookie("token");
  removeCookie("uid");
  removeCookie("auth");
};

/**
 * @description use this method to start a full-page loader
 */
const startLoader = () => {
  return PagerLoader.next(true);
};

export const ParseToken = (token) => {
  return decodeURIComponent(token).replace(/%20/g, " ");
};

/**
 * @description use this method to stop a full-page loader
 */
const stopLoader = () => {
  return PagerLoader.next(false);
};

/**
 * @description this method is to get a classname inserted into the element if the field is invalid on blur
 * @param e: react event (onblur)
 */
const handleValidate = (e) => {
  if ((!e.target.value || e.target.value == 0) && e.target.required) {
    e.target.classList.add("field-invalid");
  } else if (e.target.type === "email") {
    if (!e.target.value.match(EMAIL_REGEX)) {
      e.target.classList.add("field-invalid");
    } else {
      e.target.classList.remove("field-invalid");
    }
  } else {
    e.target.classList.remove("field-invalid");
    e.target.classList.add("field-validated");
  }
};

/**
 * @description use this method to validate email with regex
 * @param value string - email
 * @returns boolean
 */
const validateEmail = (value) => {
  if (!value) return false;
  return !!value.match(EMAIL_REGEX);
};

const validateUrl = (value) => {
  if (!value) return false;
  return !!value.match(URL_REGEX);
};

/**
 * @description use this method to validate email with regex
 * @param value string - email
 * @returns boolean
 */
const validatePassword = (value) => {
  return !!value.match(PASSWORD_REGEX);
};

const scrollTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

/**
 * @param item "$10-000"
 * @returns number - 10000
 */
const parseToNumber = (item) => {
  const number = String(item);
  if (number.includes("$")) {
    return Number(number.split("$")[1].split(",").join(""));
  } else if (number.includes("-")) {
    return Number(number.split("-").join(""));
  } else {
    return Number(item);
  }
};

/**
 * @description use this method to get how many days ago
 * @param date string - yyyy-MM-Dd
 * @returns number - 25
 */
const daysAgo = (date) => {
  let last_day = new Date(date);
  let today = new Date();
  let one_day = 1000 * 60 * 60 * 24;
  let days = Math.ceil((today.getTime() - last_day.getTime()) / one_day);
  return days;
};

/**
 * @description use this method to get how many days ago
 * @param date string - yyyy-MM-Dd
 * @returns number - 25
 */
const hoursAgo = (date) => {
  let last_time = new Date(date);
  let today = new Date();
  let one_hour = 1000 * 60 * 60;
  let hours = Math.ceil((today.getTime() - last_time.getTime()) / one_hour);
  return hours;
};

const scroollIntoHeight = (id, offset) => {
  try {
    const element = document.getElementById(id);
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } catch (error) {
    console.error("error", error);
  }
};

const getBase64UrlFromFile = (file) => {
  try {
    let payload = {
      name: file.name,
      type: file.type,
      file: file,
      base64: null,
    };
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return (reader.onloadend = () => {
      payload.base64 = reader.result;
      return payload;
    })();
  } catch (error) {
    console.error("error", error);
  }
};

/**
 * @description use this method to format a phone number to US format
 */
const formatePhoneNumber = (number) => {
  if (!number) {
    return "";
  } else if (number.includes("+1")) {
    return number;
  }
  return number
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
};

/**
 * @description use this method to generate a Google Map link using lat long
 */
const getGoogleMapLink = (lat, long) =>
  `http://maps.google.com/?q=${lat},${long}&output=embed`;

const get_browser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

const openDialog = (data) => {
  return DialogOpen.next(data);
};

const closeDialog = (Name) => {
  return DialogClose.next(Name);
};

const openDrawer = (data) => {
  return DrawerOpen.next(data);
};

const closeDrawer = (Name) => {
  return DrawerClose.next(Name);
};

const detectMobileDevice = () => {
  let isMobileViewServer = navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
  );

  return Boolean(isMobileViewServer);
};

const scrollToTop = (id) => {
  document.getElementById(id || "strip")?.scrollIntoView();
};

const nFormatter = (num) => {
  if (num >= 1000000000000000) {
    return (num / 1000000000000).toFixed(1).replace(/\.0$/, "") + "T+";
  }
  if (num >= 1000000000000) {
    return (num / 1000000000000).toFixed(1).replace(/\.0$/, "") + "T";
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

const getMeterValue = (point = 0) => {
  point = point * 100;
  let count = (180 / 100) * point;
  let val = 90 - count;
  return val;
};

export function urlValidatoin(input) {
  const validUrlRegex = /[^a-zA-Z0-9 . @ ]/; // Allow spaces by including space in the regex
  // const validUrlRegex = /^[a-zA-Z0-9-._~:/?#@!$&'()*+,;= ]*$/; // Include space in the regex here as well if needed
  return (
    input.includes("http://") ||
    input.includes("https://") ||
    validUrlRegex.test(input)
  );
}

// export function urlValidatoin(input, field) {
//   const emailRegex = /^[a-zA-Z0-9.@]*$/;
//   const passwordRegex = /^[\w!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/;

//   if (field === "email") {
//     return !emailRegex.test(input);  
//   } else if (field === "password") {
//     return !passwordRegex.test(input); 
//   }
//   return false;
// }

export function urlValidatoinAllowedUrl(input) {
  const validUrlRegex = /[^a-zA-Z0-9 ]/; // Allow spaces by including space in the regex
  // const validUrlRegex = /^[a-zA-Z0-9-._~:/?#@!$&'()*+,;= ]*$/; // Include space in the regex here as well if needed
  return validUrlRegex.test(input);
}
